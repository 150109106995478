<template>
  <section >
    <h2 class="titulo-links-escolha">ADICIONAR DISCIPLINA PARA O PROFESSOR</h2>
    <div class="form-elements">
      <div class="row">
        <div class="flex xs12">
          <va-card :title="$t('forms.inputs.title')">
            <va-card-content>
              <form>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label for="sel1" style="padding-top:15px">Selecione a etapa:</label>
                    <select class="form-control" v-model="segmentoSelecionado"  @change="buscarPeriodos(segmentoSelecionado)">
                        <option v-for="n in segmentoALL" :key="n" :value="n.id" >{{n.nome}}</option>
                    </select>
                  </div>

                  <div class="form-group col-md-6" v-if="disciplinaALL.length">
                    <label for="sel1" style="padding-top:15px">Selecione a disciplina:</label>  
                    <select class="form-control" v-model="disciplinaSelecionada" :disabled="fundamentalUmSelecionado == 1"  @change="ativarCadastro = 1">
                        <option v-for="n in disciplinaALL" :key="n" :value="n.id" >{{n.nome}}</option>
                    </select>
                  </div>
                  <div class="flex" v-if="ativarCadastro">
                    <va-button class="mr-2 mb-2"  @click="cadastrar"> {{ $t('Cadastrar') }}</va-button>  
                  </div>
                </div>
              </form>
            </va-card-content>
          </va-card>
        </div>
      </div>
    </div>
  </section >
</template>

<script>

import { Professor } from "@/class/professor.js";
import { Servidores } from "@/class/servidores.js";
import { DisciplinaEscolar } from "@/class/disciplina.js";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";


import vueMask from 'vue-jquery-mask';
import store_token_info from "@/store/store_token_info.js";


export default {
  props: {
   id:{
    },
  },
  components: {
   
  },
  name: 'Segmento-Escolar',
  data () {
    return {
        professor: {
            servidor_id:null,
            segmento_id:null,
            disciplina_id:null,
            user_id: store_token_info.usuario_logado,
        },
        segmentoALL: [],
        segmentoSelecionado:'',
        disciplinaALL:[],
        disciplinaSelecionada:'',
        fundamentalUmSelecionado:0,
        todasEspecialidadeProf: [],
        ativarCadastro:0,
    }
  },
  methods: {
    async listarTudoSegmentos() {
      try {
        let data = await SegmentoEscolar.obtemTodos();
        this.segmentoALL = data.data;
      } catch (e) {}
    },
    async listarTudoDisciplina() {
      try {
        this.disciplinaALL = [];
        let data = await DisciplinaEscolar.obtemTodos();
        this.disciplinaALL = data.data;
      } catch (e) {}
    },
    async buscarProfessorEspecialidade() {
      let data = await Servidores.visualizarEspecializaoProf(this.id);
      this.todasEspecialidadeProf = data.data;
    },
    async buscarPeriodos(segmentoSelecionado) {
      if (segmentoSelecionado == 1) { //Caso seja fundamental, nao dar opcao de selecionar disciplinas
        const found = this.todasEspecialidadeProf.find(element => element.segmento_id == segmentoSelecionado);

        if (found != undefined) {
          this.$vaToast.init({
            message: "O professor já possui cadastro na etapa selecionada!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            fullWidth: false,
          })
          this.disciplinaALL = [];
          return false;
        }
        else {
          await this.listarTudoDisciplina();
          for (var i = 0; i < this.disciplinaALL.length; i++) {
            if (this.disciplinaALL[i].nome.toLowerCase().includes("todas")) {
              this.disciplinaSelecionada = this.disciplinaALL[i].id;
              this.fundamentalUmSelecionado = 1;
              this.ativarCadastro = 1;
            }
          }
        }
      }
      else {//Caso nao seja fundamental 1, dar opcao de selecionar disciplinas
        this.disciplinaSelecionada = '';
        this.fundamentalUmSelecionado = 0;
        this.disciplinaALL = [];
        let data = await DisciplinaEscolar.obtemTodos();
        for (const el of data.data) {
          if (el.nome.toLowerCase().includes("todas")) {

          }
          else {
            //verificar se o professor possui cadastro na etapa e disciplina selecionada
            const found = this.todasEspecialidadeProf.find(element => element.segmento_id == segmentoSelecionado && element.disciplina_id == el.id);
            if (found != undefined) {
            }
            else {
              this.disciplinaALL.push(el);
            }
          }
        }
        if (!this.disciplinaALL.length) {
          this.$vaToast.init({
            message: "O professor já está cadastro em todas disciplinas da etapa selecionada!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            fullWidth: false,
          })
          return false;
        }
      }
    },
    async cadastrar() {
      try {
        this.professor.servidor_id = this.id;
        this.professor.segmento_id = this.segmentoSelecionado;  
        this.professor.disciplina_id = this.disciplinaSelecionada;
        const data = await Servidores.addEspecProfessor(this.professor);
        this.$vaToast.init({
          message: "Cadastrado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.$router.push({ name: "servidores" });
      }
      catch(e) {
        alert(e);
        this.$vaToast.init({
          message: "Erro ao cadastrar!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
      }
    },
  },
  mounted() {
    if (this.id == null) {
      this.$router.push({name: 'servidores'});
    }
    else {
      this.listarTudoSegmentos();
      this.buscarProfessorEspecialidade();
    }
  }
}
</script>

<style>
  .row.row-inside {
    max-width: none;
  }

  .form-control:focus {
    background-color: #f6f7f6;
    border-color: #673ab7;
    box-shadow: none;
  }
  .form-control {
    background-color: #f6f7f6;
    border: 1px solid #f6f6f6;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
     margin: 0;
  }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }


  .va-input-wrapper, fieldset {
    margin-bottom: 0.5rem;
  }
</style>
